* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --bgColor: #5b8e9f;
    --bgColorTint: rgba(91, 142, 159, 0.5);
    --bgSec: #6dc1c1;
    --bgSecTint: rgba(109, 193, 193, 0.5);
    --bgMix: #3b7e91;
    --bg: rgb(246 247 248/1);
    --bxShadow: -3px 0px 50px rgba(169, 222, 249, 0.25);
}

.ms-btn {
    width: 100%;
    color: white;
    background-color: var(--bgColor);
}

.ms-btn:hover {
    background-color: var(--bgSec);
    color: white;
}

.form-control {
    caret-color: var(--bgMix);
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem var(--bgSecTint);
    border-color: var(--bgColorTint);
}

.btn:focus {
    box-shadow: 0 0 0 0.25rem var(--bgSecTint);
}

.content-outline {
    border: 1px solid #d0d0d0;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 2px #d0d0d0
}