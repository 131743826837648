:root{
    --bgColor: #5b8e9f;
    --bgColorTint: rgba(91, 142, 159, 0.5);
    --bgSec: #6dc1c1;
    --bgSecTint: rgba(109, 193, 193,0.5);
    --bgMix: #3b7e91;
    --bg: rgb(246 247 248/1);
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    background-color: var(--bgColor);
}

.login-form {
    background-color: white;
    border: 1px solid #d0d0d0;
    padding: 2vh 2vw;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(111, 111, 111);
}

.logo-img{
    display: flex;
    justify-content: center;
}

#logo{
    height: 100px;
    width: auto;
}

@media(max-width: 576px){
    .login-form {
        width: 92dvw;
    }
}
@media(min-width: 577px) and (max-width: 768px){
    .login-form {
        width: 60dvw;
    }

}
@media(min-width: 769px) and (max-width: 991px){
    .login-form {
        width: 48dvw;
    }

}
@media(min-width: 992px) and (max-width: 1200px){
    .login-form {
        width: 32dvw;
    }

}
@media(min-width: 1201px){
    .login-form {
        width: 28dvw;
    }
}

.title {
    font-weight: 600;
    font-size: 2em;
    text-align: center;
}

.input {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.login-btn {
    display: flex;
    justify-content: center;
    width: 100%;
}

.submit-btn{
    width: 100%;
    background-color: var(--bgColor);
}

.submit-btn:hover{
    background-color: var(--bgSec);
}

.text {
    width: 100%;
}

.form-control {
    caret-color: var(--bgMix);
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem var(--bgSecTint);
    border-color: var(--bgColorTint);
}

.btn:focus{
    box-shadow: 0 0 0 0.25rem var(--bgSecTint);
}